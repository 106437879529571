import React from 'react'
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
// import { Col, Container, Row,Form } from 'react-bootstrap'
// import Footer from '../Components/Footer';
import { FaAngleLeft, FaAngleRight, FaAngleDoubleRight } from 'react-icons/fa';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Client1 from '../asset/img/Amardeep.webp'
import { Row, Col, Container, Figure, Card } from 'react-bootstrap';
import Result1 from '../asset/img/sec-10.jpg'
import Result2 from '../asset/img/sec-11.jpg'
import Blogimg1 from '../asset/img/blog-img1.png'
import Blogimg2 from '../asset/img/blog-img2.jpg'
import Blogimg3 from '../asset/img/blog-img3.png'
import { Redirect } from 'react-router-dom'
import emailjs from 'emailjs-com';
import Footer from '../Components/Footer';
import Testimonial from './testimonial';
import ReCAPTCHA from "react-google-recaptcha";
import { Helmet } from 'react-helmet-async';
import TestimonialNew from './testimonial_new';
import Carousel1 from '../Components/carousel1';
import line from '../asset/img/line.svg';
import { postRequest, MD5Hash, BLOG_LIST, BASE_IMAGE_URL, MASTER_IMAGE_URL } from '../util/api';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
// import {  } from '@mui/material/utils';


const PreviousBtn = (props) => {
    console.log(props);
    const { className, onClick } = props;
    return (
        <div className={className} onClick={onClick}>
            <FaAngleLeft style={{ color: "white", fontSize: "23px" }} />
        </div>
    );
};

const NextBtn = (props) => {
    const { className, onClick } = props;
    return (
        <div className={className} onClick={onClick}>
            <FaAngleRight style={{ color: "white", fontSize: "23px" }} />
        </div>
    );
};



const Contactus = () => {

    const [bloglist, setBlogList] = useState([]);
const [disableNextBtn, setDisableNextBtn] = useState(true)
const [blogPagination, setBlogPagination] = useState({
    page: 1
});


useEffect(() => {
    blogList();
}, [])
const blogList = async () => {

    let auth_token = MD5Hash('');
    let data = {
        "auth_token": auth_token,
        "page": blogPagination.page
    }
    var res = await postRequest(BLOG_LIST, data);
    // debugger;
    if (res.success) {
        setBlogList(res?.parameters?.data);
        setDisableNextBtn(true)
    } else {
        setDisableNextBtn(false)
    }
}
function handlePrevious() {
    blogPagination.page = blogPagination.page - 1
    blogList();
    window.scrollTo({
        top: 0,
        behavior: "smooth",
    });
}

function handleNext() {
    // setBlogPagination({
    //     ...blogPagination,
    blogPagination.page = blogPagination.page + 1
    // });

    blogList();
    window.scrollTo({
        top: 0,
        behavior: "smooth",
    });
}
    const client = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    const initialValues = { name: null, email: null, phonenumber: null, message: null, recaptcha: null };
    const [formValues, setFormValues] = useState(initialValues);
    const [formErrors, setFormErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);
    const [isVerified, setVerified] = useState(null);
    const handleChange = (e) => {

        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });

    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };
    // if (this.state.verified) {
    //     fetch()
    //     .then(this.handleSuccess)
    //     .catch(error => alert(error))
    //     .preventDefault()
    // }
    // else {this.setState({reply: "Please verify the ReCaptcha."})}
    React.useEffect(() => {
        // register({ name: "spam" }, { required: "prove you are no bot plz!" });
    }, []);

    const handleRecaptcha = value => {
        console.log("Captcha value:", value);
        setVerified(value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setFormErrors(validate(formValues));
        setIsSubmit(true);
        if (Object.keys(validate(formValues)).length === 0) {

         
            // emailjs.sendForm('service_x0hhgf2', 'template_a18fayd', e.target, 'E5bWl5jrD7Toy7Mcs')
            emailjs.sendForm('service_xxfunii', 'template_6iy45hc', e.target, 'gDb7UhjZgt1O7s15k')
                .then((result) => {
                    console.log(result.text);
                }, (error) => {
                    console.log(error.text);
                });
        }
    };
    useEffect(() => {
        if (Object.keys(formErrors).length === 0 && isSubmit) {

            console.log(formValues);
        }
    }, [formErrors])

    const validate = (values) => {
        const errors = {};
        const regex = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/;
        const phone = /^[6-9]\d{9}$/;

        if (!values.name) {
            errors.name = "please enter your name";
        }
        if (!values.email) {
            errors.email = "please enter your email address";
        } else if (!regex.test(values.email)) {
            errors.email = "this is not a valid email format";
        }

        if (!values.phonenumber) {
            errors.phonenumber = "please enter your phone number";
        }
        else if (!phone.test(values.phonenumber)) {
            errors.phonenumber = "please enter your valid phone number";
        }
        if (!values.message) {
            errors.message = "please enter your message";
        }
        // if(values.recaptcha == ""){
        //     errors.recaptcha = "The Captcha field cannot be blank. Please enter a value";
        // }

        return errors;
    };


    return (
        <div>
            <Helmet>
                <title>Contact us | digiDZN | Best digital markeitng agency</title>
                <meta name="description" content="Looking fro a Digital Marketing Agency to Grow your Business Online? Chat with experts at our DigiDZN website. Or Contact us at 9868877018." />
            </Helmet>
            <div className="contactus-bg">
                <Container >
                    <Row >
                        {/* <Col md={}></Col> */}
                        <Col md={6} className="contactus-heading" >
                            <div className="space-100"></div>
                            <span style={{ color: 'white', fontSize: '40px', fontWeight: '500', fontFamily: 'Roboto,sans-serif' }}>Get in touch with us</span><br />
                            {/* <h1 >
                                & get an E-book free or Digital <br />Marketing for business<br /> owners
                            </h1> */}

                            <p>
                                We love connecting with the people and sharing creative
                                ideas and thoughts every time you’ll connect with us!
                                Feel free to connect with us anytime here or send your query to us.
                                We’ll get back to you super soon.
                            </p>
                            <h6 className="mt-5">
                                digiDZN - Digital Marketing Company in Delhi
                            </h6>
                            <h6>Address - G-4, 1st Floor, Lawrence Road Industrial Area.</h6>
                            <h6>
                                Phone Number - <a href="tel:9868877018">9868877018</a> / <a href="tel:9289745760">9289745760</a>
                            </h6>

                        </Col>
                        <Col md={6} className="contact-form-bg mt-5">
                            <h1>
                                Ready to chat with our growth experts?
                            </h1>
                            {/* <pre>{JSON.stringify(formValues,undefined,2)}</pre> */}


                            <form className="contactform" onSubmit={handleSubmit}>
                                <input type='text'

                                    name="name"
                                    value={formValues.name}
                                    onChange={handleChange}
                                    placeholder="Name"
                                />
                                <p className="mb-4">{formErrors.name}</p>
                                <input type="email"

                                    name="email"
                                    value={formValues.email}
                                    onChange={handleChange}
                                    placeholder="email"
                                />
                                <p className="mb-4">{formErrors.email}</p>
                                <input type="tel"
                                    onKeyPress="if(this.value.length==10) return false;"
                                    name="phonenumber"
                                    value={formValues.phonenumber}
                                    onChange={handleChange}
                                    placeholder="phone number"
                                />
                                <p className="mb-4">{formErrors.phonenumber}</p>
                                <textarea
                                    className="mb-3"
                                    placeholder="message"
                                    name="message"
                                    value={formValues.message}
                                    onChange={handleChange}
                                >

                                </textarea>
                                <p className="mb-4">{formErrors.message}</p>
                                <ReCAPTCHA className="mb-4"
                                    sitekey="6LdqGGQaAAAAABS5Vtjm1NTJ9HdMK8rKjR96V0gE"
                                    onChange={handleRecaptcha}
                                // sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"

                                />
                                {/* <ReCAPTCHA
                                    className="g-recaptcha"
                                    sitekey="6LdqGGQaAAAAABS5Vtjm1NTJ9HdMK8rKjR96V0gE"
                                    onChange={this.onChange}
                                    theme="dark"
                                />
                                { this.state.verified ? <p id="error" className="error">{this.state.reply}</p> : null }
                                { this.state.showSuccess ? <p id="success" className="success">Thank you, we will be in touch asap.</p> : null } */}
                                {/* <p className="mb-4">{formErrors.recaptcha}</p>    */}
                                <button disabled={!isVerified} className="contact-submit-btn">send</button>
                            </form>
                            {Object.keys(formErrors).length === 0 && isSubmit ? (<Redirect to='/thank-you-page' />) : (<div></div>)}
                        </Col>
                    </Row>
                </Container>
            </div>
            <div style={{ width: "100%" }}>

                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d133230.48877538994!2d77.15415541653864!3d28.658742976553246!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d03aabadf3177%3A0xa362558c1bd55596!2sdigiDZN!5e0!3m2!1sen!2sin!4v1635436456225!5m2!1sen!2sin" width="100%" height="460" loading="lazy"></iframe>

            </div>
            {/* section4-client testimonial */}
            {/* <Testimonial /> */}
            <TestimonialNew />
            {/* section4 full growth */}
            
            <div>
                <div className='homepage-container1 py-3 py-md-5'>
                    <Container className='py-5 px-5 px-md-2'>
                        <Row>
                            <Col md={12} className="mb-5">
                                <h3 className='f-57 text-white mb-2 mb-md-5 text-center'>Full Growth Capabilities</h3>
                            </Col>
                            <Col md={5} className="mb-3 mb-md-5 p-0">
                                <div className='full-section'>
                                    <Link to='./seo' className='position-relative highlight-circle'>
                                        <img className='mb-5' src={line} />
                                        <div className='content-sections z-index-2 position-relative'>
                                            <h3 class="h4 text-white mb-4">Search Engine Optimisation – SEO</h3>
                                            <p className='banner-desc'>Get more organic traffic to your website.</p>
                                        </div>
                                        <div class="list__circle position-absolute z-index-1 mx-auto d-none d-lg-block acf-block-services-list__circle--small "></div>
                                    </Link>
                                </div>
                            </Col>
                            <Col md={1}></Col>
                            <Col md={5} className="mb-3 mb-md-5 p-0">
                                <div className='full-section'>
                                    <Link to="./campaigns" className='position-relative highlight-circle'>
                                        <img className='mb-5' src={line} />
                                        <div className='content-sections z-index-2 position-relative'>
                                            <h3 class="h4 text-white mb-4">Pay Per Click – PPC</h3>
                                            <p className='banner-desc'>Produce more scales rather than clicks</p>
                                        </div>
                                        <div class="list__circle position-absolute z-index-1 mx-auto d-none d-lg-block acf-block-services-list__circle--small "></div>
                                    </Link>
                                </div>
                            </Col>
                            <Col md={1}></Col>


                            <Col md={5} className="mb-3 mb-md-5 p-0">
                                <div className='full-section'>
                                    <Link to="#" className='position-relative highlight-circle'>
                                        <img className='mb-5' src={line} />
                                        <div className='content-sections z-index-2 position-relative'>
                                            <h3 class="h4 text-white mb-4">Website Design & Development</h3>
                                            <p className='banner-desc'>Design, build and grow.</p>
                                        </div>
                                        <div class="list__circle position-absolute z-index-1 mx-auto d-none d-lg-block acf-block-services-list__circle--small "></div>
                                    </Link>
                                </div>
                            </Col>
                            <Col md={1}></Col>
                            <Col md={5} className="mb-3 mb-md-5 p-0">
                                <div className='full-section'>
                                    <Link to="./social-media" className='position-relative highlight-circle'>
                                        <img className='mb-5' src={line} />
                                        <div className='content-sections z-index-2 position-relative'>
                                            <h3 class="h4 text-white mb-4">Social Media Marketing – SMM</h3>
                                            <p className='banner-desc'>Echoing your brand loudly in the virtual marketing ecosystem</p>
                                        </div>
                                        <div class="list__circle position-absolute z-index-1 mx-auto d-none d-lg-block acf-block-services-list__circle--small "></div>
                                    </Link>
                                </div>
                            </Col>
                            <Col md={1}></Col>

                            <Col md={5} className="mb-3 mb-md-5 p-0">
                                <div className='full-section'>
                                    <Link to="./" className='position-relative highlight-circle'>
                                        <img className='mb-5' src={line} />
                                        <div className='content-sections z-index-2 position-relative'>
                                            <h3 class="h4 text-white mb-4">Content Marketing</h3>
                                            <p className='banner-desc'>Define your company and tell the world who you are.</p>
                                        </div>
                                        <div class="list__circle position-absolute z-index-1 mx-auto d-none d-lg-block acf-block-services-list__circle--small "></div>
                                    </Link>
                                </div>
                            </Col>
                            <Col md={1}></Col>
                            <Col md={5} className="mb-3 mb-md-5p-0">
                                <div className='full-section'>
                                    <Link to="./ecommerce" className='position-relative highlight-circle'>
                                        <img className='mb-5' src={line} />
                                        <div className='content-sections z-index-2 position-relative'>
                                            <h3 class="h4 text-white mb-4">eCommerce Marketing</h3>
                                            <p className='banner-desc'>Innovate, evolve and thrive your commerce.</p>
                                        </div>
                                        <div class="list__circle position-absolute z-index-1 mx-auto d-none d-lg-block acf-block-services-list__circle--small "></div>
                                    </Link>
                                </div>
                            </Col>
                            <Col md={1}></Col>
                        </Row>
                    </Container>
                </div>
            </div>


            {/* <div className="bg-result mt-1">
                <Container>
                    <Row>
                        <Col className="text-center mt-1 heading-full-growth">
                            <h1>
                                Consistently Delivering Results
                            </h1>
                        </Col>
                    </Row>
                </Container>
                <Container>
                    <Row>
                        <Col className="mt-5 ">
                            <div className="bgcolor-result">
                                <img
                                    className="d-block img-result border-result"
                                    src={Result1}
                                    alt="Sec-10"
                                />
                            </div>
                        </Col>
                        <Col className="mt-5">
                            <div className="bgcolor-result">
                                <img
                                    className="d-block border-result img-result"
                                    src={Result2}
                                    alt="Sec-10"
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-center mt-5">
                            <Link to="/results"> <button className="growth-button">Read more</button></Link>
                        </Col>
                    </Row>
                </Container>
                <Container>
                    <Row>
                        <Col className="text-center mt-5 heading-full-growth">
                            <h1>
                                Learning Bytes
                            </h1>
                        </Col>
                    </Row>
                </Container>
            </div> */}
          
          <Carousel1 />
           <div>
                <Container>
                
                <Row className="mt-0">
                <Col xs={12} className="text-center mt-0 heading-full-growth"><h1 className='mt-0'>Learning Bytes</h1></Col>
                    {
                        bloglist.sort((a, b) => new Date(b.updated_datetime) - new Date(a.updated_datetime))
                        .slice(0, 3)
                        .map((blog) => {
                            // debugger;
                            // console.log(blog, 'Testing....')
                            const regex = /(<([^>]+)>)/ig;
                            let [year, month, dates] = blog.start_datetime.split("-");
                            let months = ["jan", "feb", "mar", "apr", "may", "jun", "jul", "aug", "sep", "oct", "nov", "dec"]

                            let values = `${months[parseInt(month) - 1]} ${dates.split(" ")[0]} ${year}`;
                            // console.log("values :", values);
                            console.log(blog.seo_url);
                            return (
                                <Col md={4} xs={12} >
                                    <Card className="text-center mt-3 rounded card-blog">
                                        <span className='catgory-blog'>{blog.blogCategories[0].category_name}</span>
                                        {
                                            blog.blog_image_url != null ? (
                                                <Card.Img variant="top"
                                                    className="blog-img border img-fluid"
                                                    src={`${BASE_IMAGE_URL}${blog.blog_image_url}`} />
                                            ) : (
                                                <div className='no-img-div'>
                                                    No image
                                                </div>
                                            )
                                        }

                                        <Card.Body>
                                            <Card.Img
                                                className='rounded-circle wid-blog'
                                                src={`${MASTER_IMAGE_URL}${blog.master_profile}`}
                                                alt='user' />
                                            <Card.Title className='blog-title mt-4'>{blog.blog_name}</Card.Title>
                                            <Card.Text className="mt-4">
                                                {/* <div>{blog.description.replace(regex, '').slice(0,150)}</div> */}
                                                <div>{ReactHtmlParser(blog?.description)}</div>
                                            </Card.Text>
                                            {/* onClick={() => navigate(blog.blog_id)} */}
                                            <Link className="read-more-txt" to={`/blog-view/${blog.seo_url}`} >Read More</Link>
                                        </Card.Body>
                                        <Card.Footer className="text-muted">
                                            <span className="text-muted footer-list-blog text-capitalize">{values}</span>
                                            <span>•</span>

                                            <span className="text-muted footer-list-blog ">Comments : {blog.blogCommentsCount}</span>
                                        </Card.Footer>
                                    </Card>
                                </Col>
                            )

                        })

                    }
                    {/* <div className='pagination-div'>
                        <button className='preview-btn' disabled={blogPagination.page <= 1} type='button' onClick={handlePrevious}> {"<< Previous"}</button>
                        <button className='next-btn' disabled={disableNextBtn == false} type='button' onClick={handleNext}>{"Next >>"}</button>
                    </div> */}
                    <Col xs={12} className='text-center py-5' >
                    <Link to='./blog'className="read-more-txt">View More</Link>
                    </Col>
                </Row>
                    {/* <Row>
                        <Col md={4}>
                            <Card className="border-card" style={{ width: "100%" }}>
                                <Card.Body>
                                    <Card.Img variant="top" class="blog-img" src={Blogimg1} />
                                    <Card.Title className="mt-2 blog-title">HOW TO USE FACEBOOK VIDEO ADS TO GROW YOUR BUSINESS</Card.Title>
                                    <Card.Subtitle className="mb-2 mt-2 blog-sub">
                                        September 24, 2021
                                    </Card.Subtitle>
                                    <Card.Text className="mb-2 mt-2 blog-text">
                                        We’re presuming you already know that videos are the way
                                        to go and that you’ll need to run Facebook advertising. And this implies that you’re
                                    </Card.Text>
                                    <Card.Link className="blog-link" href="#"> Read More <FaAngleDoubleRight></FaAngleDoubleRight></Card.Link>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={4}>
                            <Card className="border-card" style={{ width: "100%" }}>
                                <Card.Body>
                                    <Card.Img variant="top" class="blog-img" src={Blogimg2} />
                                    <Card.Title className="mt-2 blog-title">Are Facebook ads worth it? (Yes, here’s why)</Card.Title>
                                    <Card.Subtitle className="mb-2 mt-2 blog-sub">
                                        September 20, 2021
                                    </Card.Subtitle>
                                    <Card.Text className="mb-2 mt-2 blog-text">
                                        Facebook seems to have run its course. With people moving towards
                                        newer social media platforms and with all the false media, it may look like
                                    </Card.Text>
                                    <Card.Link className="blog-link" href="#"> Read More <FaAngleDoubleRight></FaAngleDoubleRight></Card.Link>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={4}>
                            <Card className="border-card" style={{ width: "100%" }}>
                                <Card.Body>
                                    <Card.Img variant="top" class="blog-img" src={Blogimg3} />
                                    <Card.Title className="mt-2 blog-title">Best Apps for Social Media Marketing 2021</Card.Title>
                                    <Card.Subtitle className="mb-2 mt-2 blog-sub">
                                        August 28, 2021
                                    </Card.Subtitle>
                                    <Card.Text className="mb-2 mt-2 blog-text">
                                        Social media is necessary for both marketers and consumers,
                                        whether you like it or not. People quickly realized how crucial it was to maintain a
                                    </Card.Text>
                                    <Card.Link className="blog-link" href="#"> Read More <FaAngleDoubleRight></FaAngleDoubleRight></Card.Link>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row> */}
                </Container>
            </div>
            {/* section6-ready to kickoff  http://www.w3.org/2000/svg*/}
            {/* <div class="custom-shape-divider-top-1635236144 mt-10">
                <svg data-name="Layer 1" xmlns="" viewBox="0 0 1200 120" preserveAspectRatio="none">
                    <path d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z" class="shape-fill"></path>
                </svg>
            </div>
            <div className="ready-kick-bg">

                <Container >
                    <Row>
                        <Col className="text-center sec-6-head">
                            <h1>Ready To Kick Off Your Growth Journey</h1>
                            <Link to="/contact-us" onRowClick={scrollToTop()} ><button className="ready-kick-btn mt-5">SPEAK TO DIGIDZN</button></Link>
                        </Col>

                    </Row>
                </Container>
            </div> */}

            <div className="ready-kick-bg yellow-bg mt-5">

                <Container >
                    <Row>
                        <Col className="text-center sec-6-head mt-4">
                            <h1 className='h4'>Ready To Kick Off Your<br></br> Growth Journey? 🚀</h1>
                            <Link to="./contact-us"> <button className="mt-2 button-custom">SPEAK TO DIGIDZN</button></Link>
                        </Col>

                    </Row>
                </Container>
            </div>

            <Footer />
        </div>
    )
}

export default Contactus
